import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Cadeau.css'

const Cadeau = (props) => {
    return (
        <div className="card">
            <a href={props.lien} className="card-link">
                    <h5 className="card-title">{props.name}</h5>
                    <p className="card-text">Prix : {props.prix} €</p>
                </a>
        </div>
    );
};

export default Cadeau;