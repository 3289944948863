import React, { useState } from 'react';
import Captcha from '../components/captcha/Captcha';
import { useNavigate } from 'react-router-dom';

const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    reason: '',
    captcha: '',
  });
  const [error, setError] = useState('');
  const [captchaToken, setCaptchaToken] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCaptchaChange = (value) => {
    setFormData({
      ...formData,
      captcha: value,
    });
  };

  const handleCaptchaTokenChange = (token) => {
    setCaptchaToken(token);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${backendUrl}/auth/validateCaptchaWithoutCookies`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${captchaToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (!response.ok) {
        setError('Captcha incorrect.');
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      // Si le captcha est correct, envoyer les données du formulaire
      console.log('Form data submitted:', formData);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="container mt-5">
      <h2>Contactez-nous</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="firstName" className="form-label">Prénom</label>
          <input
            type="text"
            className="form-control"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="lastName" className="form-label">Nom</label>
          <input
            type="text"
            className="form-control"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">Email</label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="reason" className="form-label">Raison de contact</label>
          <select
            className="form-select"
            id="reason"
            name="reason"
            value={formData.reason}
            onChange={handleChange}
          >
            <option value="">Sélectionner une raison</option>
            <option value="site">Je ne comprends pas votre site</option>
            <option value="cookies">Utilisation des cookies</option>
            <option value="usage">Comment utiliser votre site</option>
            <option value="other">J'ai une demande particulière</option>
          </select>
        </div>
        <Captcha onCaptchaChange={handleCaptchaChange} onCaptchaTokenChange={handleCaptchaTokenChange} />
        <button type="submit" className="btn btn-primary">Envoyer</button>
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </form>
    </div>
  );
};

export default ContactForm;