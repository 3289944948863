import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Accueil from './pages/Accueil';
// index.js ou App.js
import 'bootstrap/dist/css/bootstrap.min.css';
import PopUpCookies from './components/cookie/PopUpCookies';
import TermsOfService from './pages/legals/TermsOfService';
import PrivacyPolicy from './pages/legals/PrivacyPolicy';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import Tops from './pages/Tops';
import ContactForm from './pages/ContactForm';
import About from './pages/About';

function App() {
  return (
    <Router>
      <Header/>
      <Routes>
        <Route path="/" element={<Accueil/>}/>
        {/*<Route path="/register" element={<Inscription />} />*/}
        <Route path="/contact" element={<ContactForm />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/classements" element={<Tops />} />
        <Route path="/aboutus" element={<About />} />
      </Routes>
      <PopUpCookies />
      <Footer/>
    </Router>
  );
}

export default App;