const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001';
console.log(backendUrl);
// api.js

export const postQuestionnaire = async (data) => {
  try {
    

    const response = await fetch(`${backendUrl}/api/questionnaire`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Erreur lors de la communication avec le backend Node.js', error);
    throw error;
  }
};

export const postClassement = async (data) => {
  try {
    

    const response = await fetch(`${backendUrl}/api/classement`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Erreur lors de la communication avec le backend Node.js', error);
    throw error;
  }
};


export const finQuestionnaire = (data) => {
  try {
    fetch(`${backendUrl}/api/questionnaire/fin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  } catch (error) {
    console.error('Erreur lors de la communication avec le backend Node.js', error);
    throw error;
  }
};

export const getQuestionnaire = async (nbQuestion) => {
  try {
    const response = await fetch(`${backendUrl}/api/questionnaire/${nbQuestion}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Erreur lors de la communication avec le backend Node.js', error);
    throw error;
  }
};

export default backendUrl;