import React, { useState, useEffect } from 'react';

const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001';

const Captcha = ({ onCaptchaChange, onCaptchaTokenChange }) => {
  const [captchaImage, setCaptchaImage] = useState('');
  const [captchaInput, setCaptchaInput] = useState('');

  useEffect(() => {
    fetchCaptcha();
  }, []);

  const fetchCaptcha = async () => {
    try {
      const response = await fetch(`${backendUrl}/auth/generateCaptchaWithoutCookies`, {
        method: 'GET',
      });
      const data = await response.json();
      setCaptchaImage(`data:image/svg+xml;base64,${btoa(data.captcha)}`);
      onCaptchaTokenChange(data.token);
    } catch (error) {
      console.error('Erreur lors de la récupération du captcha :', error);
    }
  };

  const handleChange = (e) => {
    setCaptchaInput(e.target.value);
    onCaptchaChange(e.target.value);
  };

  return (
    <div>
      <img src={captchaImage} alt="Captcha" />
      <input
        type="text"
        name="captcha"
        value={captchaInput}
        onChange={handleChange}
        placeholder="Entrez le captcha"
      />
      <button type="button" onClick={fetchCaptcha}>
        Recharger le Captcha
      </button>
    </div>
  );
};

export default Captcha;