import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const PopUpCookies = () => {
  const [showConsent, setShowConsent] = useState(false);

  useEffect(() => {
    const consent = Cookies.get('cookieConsent');
    if (!consent) {
      setShowConsent(true);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set('cookieConsent', 'accepted', { expires: 365 });
    setShowConsent(false);
  };

  const handleDecline = () => {
    Cookies.set('cookieConsent', 'declined', { expires: 365 });
    setShowConsent(false);
  };

  if (!showConsent) {
    return null;
  }

  return (
    <div className="cookie-consent fixed-bottom bg-dark text-white">
      <div className="container d-flex justify-content-between align-items-center py-3">
        <span>Nous utilisons des cookies pour améliorer votre expérience sur notre site.</span>
        <div>
          <button className="btn btn-primary me-2" onClick={handleAccept}>
            Accepter
          </button>
          <button className="btn btn-secondary" onClick={handleDecline}>
            Refuser
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopUpCookies;