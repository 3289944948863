// src/components/header/Header.js
import React from 'react';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
        <Link to="/" className="navbar-brand mx-auto mx-lg-0 text-dark text-decoration-none" style={{ fontSize: '24px', fontWeight: 'bold' }}>
          KOFFRIR
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link to="/classements" className="nav-link">Les Tops du site</Link>
            </li>
            {/*
            <li className="nav-item">
              <Link to="/account" className="nav-link">Compte</Link>
            </li>
            
            <li className="nav-item">
              <Link to="/references" className="nav-link">Les cadeaux</Link>
            </li>*/}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Header;