// src/components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../image/logo.png';

const Footer = () => {
  return (
      <footer className="footer bg-light py-3">
          <div className="container">
              <div className="row">
                  {/* Colonne 1 */}
                  <div className="col-md-4">
                      <div className="d-flex align-items-center">
                          <img src={logo} alt="Logo KOFFRIR" style={{ height: '50px', marginRight: '10px' }} />
                          <span>&copy; 2024 KOFFRIR. Tous droits réservés.</span>
                      </div>
                  </div>

                  {/* Colonne 2 */}
                  <div className="col-md-4">
                      <h5>Liens Utiles</h5>
                      <ul className="list-unstyled">
                          <li><Link to="/terms" className="text-decoration-none">Conditions d'utilisation</Link></li>
                          <li><Link to="/privacy" className="text-decoration-none">Politique de confidentialité et gestion des cookies</Link></li>
                      </ul>
                  </div>

                  {/* Colonne 3 */}
                  <div className="col-md-4">
                      <h5>Notre Site</h5>
                      <ul className="list-unstyled">
                          <li><Link to="/aboutus" className="text-decoration-none">À propos</Link></li>
                          <li><Link to="/contact" className="text-decoration-none">Nous Contacter</Link></li>
                      </ul>
                  </div>
              </div>
          </div>
      </footer>
  );
}

export default Footer;