// Questionnaire.js
import React, { useState, useEffect } from 'react';
import { getQuestionnaire, postQuestionnaire, finQuestionnaire } from '../services/api';

function Questionnaire() {
  const initialLikedCadeauxIds = JSON.parse(sessionStorage.getItem("likedCadeauxIds")) || [];
  const [questionData, setQuestionData] = useState(null);
  const [barreInputValue, setBarreInputValue] = useState(1);
  const [likedCadeauxIds, setLikedCadeauxIds] = useState(initialLikedCadeauxIds);
  const [nbQuestion, setNbQuestion] = useState(sessionStorage.getItem("nbQuestion"));
  const nbQuestionObligatoires = 5;

  useEffect(() => {
    const fetchInitialQuestion = async () => {
      try {
        let response;
        if (sessionStorage.getItem("questionData")) {
          response = JSON.parse(sessionStorage.getItem("questionData"));
        }
        else {
          // Faites une requête au backend pour obtenir la question initiale
          response = await getQuestionnaire(1);
          sessionStorage.setItem("questionData", JSON.stringify(response));
        }
        // Mettez à jour le state avec les données de la question
        setQuestionData(response);
      } catch (error) {
        console.error('Erreur lors de la récupération de la question initiale', error);
      }
    };

    // Appeler la fonction pour récupérer la question lors du montage du composant
    fetchInitialQuestion();
  }, []); // Le tableau vide en second argument signifie que cet effet s'exécutera une seule fois, équivalent à componentDidMount

  const handleFormSubmit = async (intReponse) => {
    setQuestionData(null);
    const questionDataNew = JSON.parse(sessionStorage.getItem("questionData"));
    try {
      let nbQuestionStorage;
      if (sessionStorage.getItem("nbQuestion")) {
        nbQuestionStorage = parseInt(sessionStorage.getItem("nbQuestion"), 10);
        setNbQuestion(parseInt(sessionStorage.getItem("nbQuestion"), 10));
      } else {
        nbQuestionStorage = 1;
        setNbQuestion(1);
      }

      let responseData;
      if (nbQuestionStorage < nbQuestionObligatoires) {
        if (sessionStorage.getItem("responseUtilisateur")) {
          sessionStorage.setItem("responseUtilisateur", sessionStorage.getItem("responseUtilisateur") + ";" + intReponse);
        }
        else {
          sessionStorage.setItem("responseUtilisateur", intReponse);
        }
        responseData = await getQuestionnaire(nbQuestionStorage + 1);
      } else if (nbQuestionStorage === nbQuestionObligatoires) {
        if (sessionStorage.getItem("responseUtilisateur")) {
          sessionStorage.setItem("responseUtilisateur", sessionStorage.getItem("responseUtilisateur") + ";" + intReponse);
        }
        else {
          sessionStorage.setItem("responseUtilisateur", intReponse);
        }
        const nouvelObjet = {
          reponseUtilisateur: sessionStorage.getItem("responseUtilisateur"),
          nbQuestion: nbQuestionStorage // Vous pouvez changer cette valeur selon vos besoins
        };
        responseData = await postQuestionnaire(nouvelObjet);
      } else {
        if (sessionStorage.getItem("responseUtilisateur")) {
          sessionStorage.setItem("responseUtilisateur", sessionStorage.getItem("responseUtilisateur") + ";" + questionDataNew.question.id + ";" + intReponse);
        }
        else {
          sessionStorage.setItem("responseUtilisateur", questionDataNew.question.id + ";" + intReponse);
        }
        const nouvelObjet = {
          reponseUtilisateur: sessionStorage.getItem("responseUtilisateur"),
          nbQuestion: nbQuestionStorage // Vous pouvez changer cette valeur selon vos besoins
        };
        responseData = await postQuestionnaire(nouvelObjet);
      }

      console.log('Réponse du backend Node.js :', responseData);
      // Faire quelque chose avec la réponse du backend si nécessaire

      // Mettre à jour la question après la soumission
      sessionStorage.setItem("questionData", JSON.stringify(responseData));
      setQuestionData(responseData);
      sessionStorage.setItem("nbQuestion", nbQuestionStorage + 1);
      setNbQuestion(nbQuestionStorage + 1);
      setBarreInputValue(1);
    } catch (error) {
      console.error('Erreur lors de la soumission du questionnaire', error);
    }
  };

  const handleLike = (cadeauId) => {
    // Vérifiez si le cadeau a déjà été liké
    if (!likedCadeauxIds.includes(cadeauId)) {
      // Ajoutez le cadeau à la liste des cadeaux likés
      setLikedCadeauxIds((prevLikedCadeauxIds) => [...prevLikedCadeauxIds, cadeauId]);

      // Update sessionStorage
      sessionStorage.setItem("likedCadeauxIds", JSON.stringify([...likedCadeauxIds, cadeauId]));

      // Enregistrez le like dans sessionStorage ou effectuez d'autres opérations nécessaires
      sessionStorage.setItem("responseUtilisateur", sessionStorage.getItem("responseUtilisateur") + ";" + cadeauId);
    }
  };

  const resetVariables = async () => {
    if (questionData.laReponseEstLeCadeau && likedCadeauxIds.length > 0) {
      const nouvelObjet = {
        reponseUtilisateur: sessionStorage.getItem("responseUtilisateur"),
      };
      // Réinitialiser les variables ici
      finQuestionnaire(nouvelObjet);
    }
    let response = await getQuestionnaire(1);
    sessionStorage.setItem("questionData", JSON.stringify(response));
    // Mettez à jour le state avec les données de la question
    setQuestionData(response);
    setBarreInputValue(null);
    setLikedCadeauxIds([]);
    sessionStorage.removeItem("responseUtilisateur");
    sessionStorage.removeItem("nbQuestion");
    setNbQuestion(0);
  };

  const goToPreviousQuestion = async () => {
    let nbQuestionStorage;
    if (sessionStorage.getItem("nbQuestion")) {
      nbQuestionStorage = parseInt(sessionStorage.getItem("nbQuestion"), 10);
      setNbQuestion(parseInt(sessionStorage.getItem("nbQuestion"), 10));
    } else {
      nbQuestionStorage = 1;
      setNbQuestion(1);
    }
    let responseData;
    let arrayOfNumbers;
    let lastIndex;
    if (sessionStorage.getItem("responseUtilisateur")) {
      arrayOfNumbers = sessionStorage.getItem("responseUtilisateur").split(';');
      lastIndex = arrayOfNumbers.length - 1;
      if (nbQuestionStorage < nbQuestionObligatoires + 2) {
        responseData = await getQuestionnaire(nbQuestionStorage - 1);
        arrayOfNumbers.splice(-1, 1);
        sessionStorage.setItem("responseUtilisateur", arrayOfNumbers.join(";"));

      } else {
        responseData = await getQuestionnaire(arrayOfNumbers[lastIndex - 1]);
        arrayOfNumbers.splice(-2, 2);
        sessionStorage.setItem("responseUtilisateur", arrayOfNumbers.join(";"));
      }

      // Mettre à jour la question après la soumission
      sessionStorage.setItem("questionData", JSON.stringify(responseData));
      setQuestionData(responseData);
      sessionStorage.setItem("nbQuestion", nbQuestionStorage - 1);
      setNbQuestion(nbQuestionStorage - 1);
    }


  };

  const handleFormSubmitLink = (link) => {
    // Logique de soumission du formulaire ici
    console.log("Valeur soumise :", barreInputValue);

    window.open(link, '_blank');
  };
  // Votre logique du questionnaire ici, mettez à jour "answers" en conséquence
  // Utilisez questionData pour afficher la question dans votre composant

  return (
    <div className="row justify-content-center my-3 bg-light p-4 rounded">
      <div className="col-md-8">
        {questionData ? (
          <div>
            {!questionData.laReponseEstLeCadeau && questionData.question && (
              <>
                <div className="mb-3">
                  <h5>{questionData.question.question}</h5>
                </div>

                {questionData.question.choixRange ? (
                  <div className="mb-3">
                    <input
                      type="range"
                      className="form-range"
                      min={questionData.question.borneMin}
                      max={questionData.question.borneMax}
                      step={1}
                      value={barreInputValue}
                      onChange={(e) => setBarreInputValue(e.target.value)}
                    />
                    <input
                      type="number"
                      className="form-control mt-2"
                      min={questionData.question.borneMin}
                      max={questionData.question.borneMax}
                      value={barreInputValue}
                      onChange={(e) => {
                        let value = parseInt(e.target.value, 10);
                        if (value < questionData.question.borneMin) {
                          value = questionData.question.borneMin;
                        }
                        if (value > questionData.question.borneMax) {
                          value = questionData.question.borneMax;
                        }
                        setBarreInputValue(value);
                      }}
                    />
                    <button className="btn btn-primary mt-2" onClick={() => handleFormSubmit(barreInputValue)}>
                      Valider
                    </button>
                  </div>
                ) : (
                  <div className="container text-center mb-3">
                    <div className="row justify-content-center btn-group">
                      {questionData.question.nbChoix >= 1 && (
                        <div className="col-sm-6 mb-2">
                          <button
                            type="button"
                            className="btn btn-outline-primary btn-block"
                            onClick={() => handleFormSubmit(1)}
                          >
                            {JSON.parse(questionData.question.choix).choix[0]}
                          </button>
                        </div>
                      )}
                      {questionData.question.nbChoix >= 2 && (
                        <div className="col-sm-6 mb-2">
                          <button
                            type="button"
                            className="btn btn-outline-primary btn-block"
                            onClick={() => handleFormSubmit(2)}
                          >
                            {JSON.parse(questionData.question.choix).choix[1]}
                          </button>
                        </div>
                      )}
                      {questionData.question.nbChoix >= 3 && (
                        <div className="col-sm-6 mb-2">
                          <button
                            type="button"
                            className="btn btn-outline-primary btn-block"
                            onClick={() => handleFormSubmit(3)}
                          >
                            {JSON.parse(questionData.question.choix).choix[2]}
                          </button>
                        </div>
                      )}
                      {questionData.question.nbChoix === 4 && (
                        <div className="col-sm-6 mb-2">
                          <button
                            type="button"
                            className="btn btn-outline-primary btn-block"
                            onClick={() => handleFormSubmit(4)}
                          >
                            {JSON.parse(questionData.question.choix).choix[3]}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {nbQuestion > 1 && (
                  <div className="mb-3">
                    <button className="btn btn-secondary" onClick={goToPreviousQuestion}>
                      Question précédente
                    </button>
                  </div>
                )}
              </>
            )}
            {questionData.laReponseEstLeCadeau && questionData.cadeaux && (
              <div>
                <h3>Liste des cadeaux :</h3>
                <ul className="list-group">
                  {questionData.cadeaux.map((cadeau) => (
                    <li key={cadeau.id} className="list-group-item d-flex justify-content-between align-items-center">
                      {cadeau.name} - Prix: {cadeau.prix}
                      {cadeau.lien ? (
                        <button className="btn btn-info btn-sm" onClick={() => handleFormSubmitLink(cadeau.lien)}>
                          Lien
                        </button>
                      ) : (
                        <div>Pas de lien disponible</div>
                      )}
                      <button
                        className="btn btn-outline-success btn-sm"
                        onClick={() => handleLike(cadeau.id)}
                        disabled={likedCadeauxIds.includes(cadeau.id)}
                      >
                        Like
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ) : (
          <p>Chargement des données...</p>
        )}
        {nbQuestion > 1 && (
          <div className="mt-3">
            <button className="btn btn-danger" onClick={resetVariables}>
              Recommencer
            </button>
          </div>
        )}
      </div>
    </div>

  );
}

export default Questionnaire;
