import React from 'react';
import Classement from '../components/classement/Classement';

const Tops = () => {

    return (
        <div className="container mt-5">
            <div className="row">
                <Classement title="Cadeaux à 20 euros" type="price" min="15" max="23" />
                <Classement title="Cadeaux à 50 euros" type="price" min="23" max="55" />
                <Classement title="Cadeaux à plus de 100 euros" type="price" min="100" max="3000" />
                <Classement title="Cadeaux pour une personne de 20 ans" type="age" min="15" max="25"/>
                <Classement title="Cadeaux pour une personne de 40 ans" type="age" min="30" max="50" />
                <Classement title="Cadeaux pour une personne de 60 ans" type="age" min="55" max="200" />
            </div>
        </div>
    );
};

export default Tops;