import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Questionnaire from '../components/Questionnaire';
import Header from '../components/header/Header';
import Cadeau from '../components/cadeau/Cadeau';
import Classement from '../components/classement/Classement';
import AdsComponent from '../components/AdsComponent';

function Accueil() {
    // État local pour afficher le questionnaire
    const [showQuestionnaire, setShowQuestionnaire] = useState();

    // Fonction pour démarrer le questionnaire
    const handleStartQuestionnaire = () => {
        setShowQuestionnaire(true);
        // Stocker dans sessionStorage lorsque le questionnaire est démarré
        sessionStorage.setItem('showQuestionnaire', 'true');
    };

    // Effet pour nettoyer sessionStorage lorsque le composant est démonté
    useEffect(() => {
        const storedShowQuestionnaire = sessionStorage.getItem('showQuestionnaire') === 'true';
        setShowQuestionnaire(storedShowQuestionnaire);
    }, []);

    return (
        <main className="container-fluid overflow-hidden text-center">
            <div className="row justify-content-center m-3">
                <div className="col col-md-8">
                    <div className="mb-5">
                        <p className="lead">Bienvenue sur KOFFRIR, votre destination ultime pour trouver le cadeau idéal, que ce soit pour Noël, un anniversaire ou toute autre célébration spéciale. Nous avons sélectionné soigneusement une gamme de cadeaux uniques et attentionnés pour vous aider à faire plaisir à vos proches.</p>
                        <p className="lead">Explorez notre vaste catalogue de cadeaux tendance, des articles personnalisés aux dernières nouveautés en passant par les promotions spéciales. Que vous cherchiez quelque chose de spécial pour un être cher ou que vous souhaitiez vous faire plaisir, KOFFRIR est là pour vous guider à chaque étape.</p>
                        <p className="lead">Nos experts ont parcouru le monde pour dénicher des cadeaux qui émerveilleront et surprendront. Que vous recherchiez un cadeau luxueux pour impressionner ou quelque chose de plus modeste mais plein de sens, notre sélection diversifiée vous offre des options adaptées à tous les budgets et à toutes les occasions.</p>
                        {!showQuestionnaire && (
                            <button className="btn btn-lg btn-primary mt-3" onClick={handleStartQuestionnaire}>
                                APPUYEZ ICI POUR COMMENCER LE QUESTIONNAIRE !
                            </button>
                        )}
                    </div>
                </div>
            </div>

            {showQuestionnaire && (
                <div className="row justify-content-center m-3">
                    <div className="col col-md-8">
                        <Questionnaire />
                    </div>
                </div>
            )}
            <div className="row justify-content-evenly m-3">
                <div className="col col-md-10">
                    <Classement title="Cadeaux Tendance" type="price" min="0" max="10000" nombre="3" link="/classements" />
                </div>
            </div>
            <div className="row justify-content-evenly m-3">
                <div className="col col-md-10">
                    <Classement title="Cadeaux Sponsor" type="price" min="0" max="10000" nombre="3" link="/classements" />
                </div>
            </div>
            <div className="row justify-content-evenly m-3">
                <div className="col col-md-10">
                    <Classement title="Top soldes d'été" type="price" min="0" max="10000" nombre="3" link="/classements" />
                </div>
            </div>
            <AdsComponent dataAdSlot='7429318286' />
            <div className="row justify-content-evenly m-3">
                <div className="col col-md-10">
                    <Classement title="Cadeaux 2024" type="price" min="0" max="10000" nombre="3" link="/classements" />
                </div>
            </div>

        </main >
    );
}

export default Accueil;
