import React, { useEffect, useState } from 'react';
import { postClassement } from '../../services/api';
import Cadeau from '../cadeau/Cadeau';

import { Link } from 'react-router-dom';

const Classement = (props) => {
    const [classementData, setClassementData] = useState(null);
    useEffect(() => {
        const fetchInitialQuestion = async () => {
            try {
                const nouvelObjet = {
                    type: props.type,
                    borneMin: props.min,
                    borneMax: props.max// Vous pouvez changer cette valeur selon vos besoins
                };
                let response = await postClassement(nouvelObjet);
                console.log(response);
                setClassementData(response);
            } catch (error) {
                console.error('Erreur lors de la récupération de la question initiale', error);
            }
        };

        // Appeler la fonction pour récupérer la question lors du montage du composant
        fetchInitialQuestion();
    }, []);

    return (
        <>
            <div class="row justify-content-between">
                <h4 class="col-3 text-start">{props.title}</h4>
                <div class="col-3">
                    {props.link && (
                        <Link to="/classements" className="btn btn-primary btn-lg">
                            Voir plus de cadeaux
                        </Link>
                    )}

                </div>
            </div>
            {classementData && (
                <div className="row">
                    {classementData.slice(0, props.nombre || 10).map((cadeau) => (

                        <div class="col-md-4">
                            <Cadeau key={cadeau.id} name={cadeau.name} lien={cadeau.lien} prix={cadeau.prix} />
                        </div>

                    ))}
                </div>
            )}

        </>
    );
};

export default Classement;